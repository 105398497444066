import { html, css, LitElement } from 'lit-element';

import { renderRipple } from '../../app-render/elements/ripple/index.js';



 //export class AppButton extends actionsMixin(LitElement) {
 export class AppButton extends LitElement {
  static get styles() {
    return []
  }

  static get properties() {
    return {
      active: { type: Boolean },
      data: { type: Object },
      action: { type: String }
    //  actions: { type: Object },
    };
  }


  constructor() {
    super();
    this.data={};
    this.action='';
    this.active=false;
  }






  render() {
    const { data, action, active } = this;


    return html`
    <style>



    .flex-none { flex: none;}.flex{display:flex}.inline-flex{display:inline-flex}.flex-column{flex-direction:column}.flex-row{flex-direction:row}.flex-wrap{flex-wrap:wrap}.flex-nowrap{flex-wrap:nowrap}.flex-wrap-reverse{flex-wrap:wrap-reverse}.flex-column-reverse{flex-direction:column-reverse}.flex-row-reverse{flex-direction:row-reverse}.items-start{align-items:flex-start}.items-end{align-items:flex-end}.items-center{align-items:center}.items-baseline{align-items:baseline}.items-stretch{align-items:stretch}.self-start{align-self:flex-start}.self-end{align-self:flex-end}.self-center{align-self:center}.self-baseline{align-self:baseline}.self-stretch{align-self:stretch}.justify-start{justify-content:flex-start}.justify-end{justify-content:flex-end}.justify-center{justify-content:center}.justify-between{justify-content:space-between}.justify-around{justify-content:space-around}.order-0{order:0}.order-1{order:1}.order-2{order:2}.order-3{order:3}.order-4{order:4}.order-5{order:5}.order-6{order:6}.order-7{order:7}.order-8{order:8}.order-last{order:99999}.flex-grow1{flex-grow:1}.flex-grow2{flex-grow:2}.flex-grow3{flex-grow:3}.flex-grow4{flex-grow:4}.flex .flex{height:100%}.flex .flex *{height:auto;width:100%;flex-wrap:wrap;margin:0;display:inherit;-webkit-align-items:center;-ms-flex-align:center;align-items:center;}.w-100{width:100%}.h-25{height:25%}.h-50{height:50%}.h-75{height:75%}.h-100{height:100%}.min-h-100{min-height:100%}.vh-25{height:25vh}.vh-50{height:50vh}.vh-75{height:75vh}.vh-100{height:100vh}


    button{
        display: inline-block;
        vertical-align: middle;
        border: none;
        margin: 0 auto !important;
        padding: 0;
        width: inherit;
        text-decoration: none;
        color: rgba(0, 0, 0, .7);
        background-color: rgba(0, 0, 0, .05);
        font-family: sans-serif;
        font-size: 1rem;
        cursor: pointer;
        text-align: center;
        transition: background 250ms ease-in-out, transform 150ms ease;
        -webkit-appearance: none;
        -moz-appearance: none;
        white-space: nowrap;
    }





    .btn:focus {
        outline: 0;
        outline-offset: 0
    }

    button[inverted] {
        background-color: rgba(0, 0, 0, .7);
        color: rgba(255, 255, 255, .87)
    }

    button:focus,
    button:hover {
        color: rgba(0, 0, 0, 1);
        background-color: rgba(0, 0, 0, .125)
    }

    button[inverted]:focus,
    button[inverted]:hover {
        background-color: rgba(0, 0, 0, .8)
    }

    button:focus {
        outline: 1px solid #fff;
        outline-offset: -4px;
        box-shadow: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none
    }

    button:active {
        transform: scale(.99)
    }

    .btn {
        font-family: Roboto, 'Helvetica Neue, Helvetica, Arial', sans-serif;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: .05em;
        text-transform: uppercase;
        overflow: hidden;
        padding: 10px 14px;
        /*min-width: 88px;*/
        width: inherit;
    }
/*
    .btn svg { width: 20px; height: 20px;margin-right:.25em;}
*/



    .btn[tab] {
        height: inherit;
        border-bottom: 1px solid transparent margin: 0
    }

    .btn[tab]:active,
    .btn[tab]:hover {
        border-bottom: 1px solid rgba(256, 256, 256, .5)
    }

    .btn[tab]:focus,
    .btn[tab][active] {
        border-bottom: 1px solid #fff
    }

    .btn-danger,
    .btn-danger:active,
    .btn-info,
    .btn-info:active,
    .btn-primary,
    .btn-primary:active,
    .btn-success,
    .btn-success:active,
    .btn-warning,
    .btn-warning:active {
        color: rgba(255, 255, 255, .87)
    }

    [inverted].btn-danger,
    [inverted].btn-danger:active,
    [inverted].btn-info,
    [inverted].btn-info:active,
    [inverted].btn-primary,
    [inverted].btn-primary:active,
    [inverted].btn-success,
    [inverted].btn-success:active,
    [inverted].btn-warning,
    [inverted].btn-warning:active {
        color: rgba(255, 255, 255, .87);
        background-color: rgba(255, 255, 255, .05)
    }

    .btn-danger:focus,
    .btn-danger:hover,
    .btn-info:focus,
    .btn-info:hover,
    .btn-primary:focus,
    .btn-primary:hover,
    .btn-success:focus,
    .btn-success:hover,
    .btn-warning:focus,
    .btn-warning:hover {
        color: rgba(255, 255, 255, 1)
    }

    .btn-info:focus,
    [inverted].btn-danger:focus,
    [inverted].btn-danger:hover,
    [inverted].btn-info:hover,
    [inverted].btn-primary:focus,
    [inverted].btn-primary:hover,
    [inverted].btn-success:focus,
    [inverted].btn-success:hover,
    [inverted].btn-warning:focus,
    [inverted].btn-warning:hover {
        color: rgba(255, 255, 255, 1);
        background-color: rgba(255, 255, 255, .1)
    }

    .btn-primary {
        background-color: #5677fc
    }

    .btn-primary:active,
    .btn-primary:hover {
        background-color: #4e6cef
    }

    .btn-primary:focus {
        background-color: #455ede
    }

    .btn-info {
        background-color: #03a9f4
    }

    .btn-info:active,
    .btn-info:hover {
        background-color: #039be5
    }

    .btn-info:focus {
        background-color: #0288d1
    }

    .btn-success {
        background-color: #259b24
    }

    .btn-success:active,
    .btn-success:hover {
        background-color: #0a8f08
    }

    .btn-success:focus {
        background-color: #0a7e07
    }

    .btn-warning {
        background-color: #ffc107
    }

    .btn-warning:active,
    .btn-warning:hover {
        background-color: #ffb300
    }

    .btn-warning:focus {
        background-color: #ffa000
    }

    .btn-danger {
        background-color: #ff5722
    }

    .btn-danger:active,
    .btn-danger:hover {
        background-color: #f4511e
    }

    .btn-danger:focus {
        background-color: #e64a19
    }



    .btn-lg {
        min-width: 122px;
        padding: 10px 16px;
        margin: 1rem 0;
        font-size: 18px
    }

    .btn-sm {
        min-width: 64px;
        padding: 4px 12px;
        font-size: 12px
    }

    .btn-xs {
        min-width: 46px;
        padding: 2px 10px;
        font-size: 10px
    }

    [raised].btn {
        -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12), 0 1px 2px 0 rgba(0, 0, 0, .24);
        -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12), 0 1px 2px 0 rgba(0, 0, 0, .24);
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12), 0 1px 2px 0 rgba(0, 0, 0, .24)
    }





  /*  [icon].btn svg {
        width: 20px;
        height: 2px;
        min-width: 24px;
        padding: 0px
    }*/

    [icon].btn {
      width: 36px;
        height: 36px;
        min-width: 36px;
        padding: 5px
    }

    [icon].btn-lg {
        width: 48px;
        height: 48px;
        min-width: 48px;
        padding: 6px
    }

    [icon].btn-sm {
        width: 30px;
        height: 30px;
        min-width: 30px;
        padding: 5px
    }

    [icon].btn-xs {
        width: 24px;
        height: 24px;
        min-width: 24px;
        padding: 4px
    }

    [icon].btn.fab {
        border-radius: 50%;
        padding: 6px
    }

    [icon].btn-lg.fab {
        padding: 10px
    }

    [icon].btn-sm.fab {
        padding: 7px
    }

    [icon].btn-xs.fab {
        padding: 6px
    }

    .tl {
        text-align: left
    }

    .ttl {
        text-transform: lowercase
    }

    .btn[ripple] {
        transform: rotateZ(360deg);
        transition: all .2s ease
    }

    .content {
      display:inline;
    }




    </style>
    ${data
      ? html `
        <button
        type="${data.type||'button'}"
        class="btn ${data.params.styles}"
        ?disabled="${data.params.disabled}"
        ?tab="${data.params.tab}"
        ?fab="${data.params.fab}"
        ?raised="${data.params.raised}"
        ?inverted="${data.params.inverted}"
        ?ripple="${data.params.ripple?true:false}"
        ?icon="${data.params.icon?true:false}"
        @click="${(e) => this.__clickAction(e, data.action)}"
        >

<slot></slot>


        ${ data.params.ripple==='inner' ? renderRipple({}) : null }

        </button>
        ${ data.params.ripple==='outer' ? renderRipple({}) : null }

      `
      : null
    }


    `;
  }

  /*static get actions() {
      return actions;
  }*/


  firstUpdated() {
    const hostId= this.data && this.data.id;
    //console.log(this.parentElement.host)

    //const hostStyles= this.data && this.data.styles;
    //hostStyles ? this.className=hostStyles : null;
/*
    hostId ? this.id=hostId : null;


*/
  }




    __clickAction(e, type) {
      e.preventDefault();
      // const action= (this.data && this.data.action) || {type:'click'};
      this.dispatchEvent(new CustomEvent('clicked'/*, {detail: {action: action}}*/));
      //let currentAction = actions.filter(res => res.type === action.type)[0];
      //currentAction.callback && currentAction.callback(e, action, this);
    }
}
