import { LitElement, html } from 'lit-element';
import { renderImage } from '../../image'

const renderElements= (data, renderElement) => data ?
data.map(elems => renderElement(elems))
: null


function renderUserMenu (data, renderElement) {
  const token = document.cookie.replace(/(?:(?:^|.*;\s*)__session\s*\=\s*([^;]*).*$)|^.*$/, "$1");
  if(token){
    const renderedToken= JSON.parse(atob(token.split('.')[1]));
    data.auth[0].dropdown.trigger.image.title=renderedToken.name;
    data.auth[0].dropdown.trigger.image.src=renderedToken.picture.indexOf('googleusercontent.com') != -1 || renderedToken.picture.indexOf('ggpht.com') != -1 ? renderedToken.picture + '?sz=' +40 : renderedToken.picture;
    data.auth[0].dropdown.trigger.image.alt=renderedToken.email;
    return renderElements(data.auth, renderElement)

  } else {

    return renderElements(data.anonymous, renderElement)
  }

}



export const renderFunction = (data, renderElement) => data.name === 'renderUserMenu' ? renderUserMenu(data.elements, renderElement)
: null
