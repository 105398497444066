import { LitElement, html } from 'lit-element';
import { renderImage } from '../../image/index.js';

import { actions, __handleInteraction } from '../../../_actions.js';


export const renderLink = (data) => data
? html `
<a href="${data.action.href}" class="${data.styles}" title=${data.title} @click="${(e) => __handleInteraction(e, data.action)}">
${data.image ? renderImage(data.image) : data.label }
</a>
`
: null
