import { LitElement, html } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map.js';
import '../../../../app-ripple/app-ripple.js';


export const renderRipple = (data, meta) => data
? html`
<app-ripple
.data="${data}"
>
</app-ripple>

`
: null
