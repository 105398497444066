import { LitElement, html } from 'lit-element';



	export const renderImage = (data) => data
	? html`
	<img src="${data.src}" alt="${data.alt}" class="self-center" id="menu_user_trigger">
	`

: null
