import { LitElement, html } from 'lit-element';
import '../../../../app-button/app-button.js';

import { actions, __handleInteraction } from '../../../_actions.js';


export const renderButton = (data, elems) => data
? html`
<app-button
.data="${data}"
id="${data.id}"
class="inline-flex ${data.styles}"
@clicked="${(e) => __handleInteraction(e, data.action)}"
>
${elems}
</app-button>


`
: null
