import { html, css, LitElement } from 'lit-element';


 export class AppRipple extends LitElement {

  static get styles() {
    return []
  }

  static get properties() {
    return {
      active: { type: Boolean },
      data: { type: Object },
      action: { type: String }
    //  actions: { type: Object },
    };
  }


  constructor() {
    super();
    this.data={};
    this.action='';
    this.active=false;
  }






  render() {
    const { data, action, active } = this;


    return html`
    <style>
    .ripple-container{position:absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;background:0 0}.ripple-effect{position:absolute;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%);opacity:0;width:0;height:0;border-radius:50%;background:rgba(255,255,255,.4)}.ripple-container.ripple-effect-animation .ripple-effect{-webkit-animation:ripple .4s ease-in;animation:ripple .4s ease-in}@-webkit-keyframes ripple{0%{opacity:0}30%{opacity:1}100%{opacity:0;padding-bottom:200%;width:200%}}@media screen and (min-width:40em){.btn-lg{min-width:122px;padding:10px 16px;margin:1rem 0;font-size:18px;line-height:1.3}}
    </style>

    <div class="ripple-container"
            @click="${e => this._triggerRipple(e)}">
            <span class="ripple-effect"></span>
            </div>


    `;
  }






  _triggerRipple (e) {
      var offset=e.target.getBoundingClientRect()
      var effect = e.currentTarget.querySelector('.ripple-effect');
      effect.style.top = (e.clientY-offset.top) + "px";
      effect.style.left = (e.clientX-offset.left) + "px";
      effect.parentElement.classList.add('ripple-effect-animation');
      this.addEventListener('animationend', this._removeAnimation(e));

  }

  _removeAnimation (e) {
    var rip=e.target;
    if (rip.classList) {
      setTimeout(() => {rip.classList.remove('ripple-effect-animation')}, 400);
    } else {
      rip.className = rip.className.replace(new RegExp('(^|\\b)' + 'ripple-effect-animation'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
  };

}
