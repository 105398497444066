import { LitElement, html } from 'lit-element';
import { Icons } from './config.js';

export const renderIcon = (data) => data
? html`
<svg  class="" viewBox="0 0 24 24" fill="currentColor">
<title>"${data.title}"</title>
<path d="${Icons[data.path]}"></path>
</svg>
  `
  : null
