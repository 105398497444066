import { html, css, LitElement } from 'lit-element';
//import { backdrop } from './styles';
import '../../app-input/app-input.js';


 export class AppForm extends LitElement {

  static get styles() {
  //  return [backdrop]
  }

  static get properties() {
    return {
      active: { type: Boolean },
      data: { type: Object },
      invalid: { type: Array },
    };
  }


  constructor() {
    super(...arguments);
    this.active = false;
    this.invalid = [];
    this.data = {};

  }






  render() {
    const { active, invalid, data} = this;


/*
<app-input ?active .data="${radio}"></app-input>
<app-input ?active .data="${checkbox}"></app-input>

<app-input ?active .data="${select}"></app-input>
<app-input ?active .data="${input}"></app-input>

*/

    const renderInputField = (data) =>
    data.type === 'text' ? 'renderTextField'
    : data.type === 'select' ? 'renderSelect'
    : 'other'


const renderElements = (data) => data.map((element) =>
element ?  html `<app-input ?active .data="${element.input}" .value .valid="${element.input.required?false:true}" id="${element.input.name}"></app-input>`
: null
)



    const renderFieldsets = (data) => data
    ? data.map((element) => html`
    <fieldset id=${element.id}>
    ${element.legend?html`<legend>${element.legend}</legend>`:null}
    <div class="flex ${element.class||''}">
    ${renderElements(element.elements || [])}
    </div>

      `
    )
    : null




    return html`
    <style>
    * {
    box-sizing: border-box;
    }
    /* Form */
    form{padding-top:1em}fieldset,input[type=file]{border-radius:5px;border:1px solid transparent;padding:0;margin:0;width:100%}fieldset{padding:1em 0 2em;margin:0 auto;}legend{font-weight:700;font-size:.95em color: rgba(0,0,0,.6);padding:.875rem 0}app-input{width:100%}app-input:not(:last-of-type):not(:first-of-type){margin:0 .25em}*>app-input:last-of-type{margin:0 0 0 .25em}*>app-input:first-of-type{margin:0 .25em 0 0}



.flex-none { flex: none;}.flex{display:flex}.inline-flex{display:inline-flex}.flex-column{flex-direction:column}.flex-row{flex-direction:row}.flex-wrap{flex-wrap:wrap}.flex-nowrap{flex-wrap:nowrap}.flex-wrap-reverse{flex-wrap:wrap-reverse}.flex-column-reverse{flex-direction:column-reverse}.flex-row-reverse{flex-direction:row-reverse}.items-start{align-items:flex-start}.items-end{align-items:flex-end}.items-center{align-items:center}.items-baseline{align-items:baseline}.items-stretch{align-items:stretch}.self-start{align-self:flex-start}.self-end{align-self:flex-end}.self-center{align-self:center}.self-baseline{align-self:baseline}.self-stretch{align-self:stretch}.justify-start{justify-content:flex-start}.justify-end{justify-content:flex-end}.justify-center{justify-content:center}.justify-between{justify-content:space-between}.justify-around{justify-content:space-around}.order-0{order:0}.order-1{order:1}.order-2{order:2}.order-3{order:3}.order-4{order:4}.order-5{order:5}.order-6{order:6}.order-7{order:7}.order-8{order:8}.order-last{order:99999}.flex-grow1{flex-grow:1}.flex-grow2{flex-grow:2}.flex-grow3{flex-grow:3}.flex-grow4{flex-grow:4}.flex .flex{height:100%}.flex .flex *{height:auto;width:100%;flex-wrap:wrap;margin:0;display:inherit;-webkit-align-items:center;-ms-flex-align:center;align-items:center;}.w-100{width:100%}.h-25{height:25%}.h-50{height:50%}.h-75{height:75%}.h-100{height:100%}.min-h-100{min-height:100%}.vh-25{height:25vh}.vh-50{height:50vh}.vh-75{height:75vh}.vh-100{height:100vh}


.row{margin:0 auto;width:100%;}


input[type="submit"]{
      display: inline-block;
      vertical-align: middle;
      border: none;
      margin: 0 auto !important;
      padding: 0;
      width: inherit;
      text-decoration: none;
      color: rgba(0, 0, 0, .7);
      background-color: rgba(0, 0, 0, .05);
      font-family: sans-serif;
      font-size: 1rem;
      cursor: pointer;
      text-align: center;
      transition: background 250ms ease-in-out, transform 150ms ease;
      -webkit-appearance: none;
      -moz-appearance: none;
      white-space: nowrap;
}


    .btn:focus {
        outline: 0;
        outline-offset: 0
    }

    input[type="submit"][inverted] {
        background-color: rgba(0, 0, 0, .7);
        color: rgba(255, 255, 255, .87)
    }

    input[type="submit"]:focus,
    input[type="submit"]:hover {
        color: rgba(0, 0, 0, 1);
        background-color: rgba(0, 0, 0, .125)
    }

    input[type="submit"][inverted]:focus,
    input[type="submit"][inverted]:hover {
        background-color: rgba(0, 0, 0, .8)
    }

    input[type="submit"]:focus {
        outline: 1px solid #fff;
        outline-offset: -4px;
        box-shadow: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none
    }

    input[type="submit"]:active {
        transform: scale(.99)
    }



.btn:focus {
    outline: 0;
    outline-offset: 0
}
.btn {
    font-family: Roboto, 'Helvetica Neue, Helvetica, Arial', sans-serif;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: .05em;
    text-transform: uppercase;
    overflow: hidden;
    padding: 10px 14px;
    /*min-width: 88px;*/
    width: inherit;

}

@media screen and (min-width:40em){.submit, slot{align-items:flex-end}}}

    </style>

    <form name="${data.name}" >
    ${renderFieldsets(data.fieldsets)}

<div class="submit flex flex-column">

<slot class="flex flex-column" @click="${(e) => this._handleFormSubmit(e)}"></slot>


    </div>
    </form>
    `;
  }

//    <button type='submit' class="btn" @click="${(e) => this._handleFormSubmit(e)}">Send Request</button>






//@media screen and (min-width:40em){slot{border:1px solid blue;display:inline-flex; width:100%;}}

  //flex flex-column flex-grow1 items-end
//  <button id="submit" @click="${(e) => this._handleFormSubmit(e)}"></button>

//<span data-info='' data-error=${data.errorMessage}   ?invalid="${this.invalid}"



firstUpdated(){
  const form = this.shadowRoot.querySelector('form');
  form.addEventListener('submit', this._postData, {
  once: true,
  capture: false
});

}

_handleFormSubmit(e){
  console.log('hold');

  // See https://stackoverflow.com/questions/4215737/convert-array-to-object

  e.preventDefault();
  const reduceObject = (values) => values.reduce((result, item) => {
    var key = Object.keys(item)[0]; //first property: a, b, c
    result[key] = item[key];
    return result;
  }, {})

  const form = this.shadowRoot.querySelector('form');
  const inputs = [...form.querySelectorAll('app-input')].map((items) => items)
  const invalid= inputs.reduce((result, item) => {
    if(!item.valid){
      item.invalid=true;
      return true;
    }
  })


if(!invalid){
  const data= inputs.map((items) => ( {[items.data.name] : items.value} ) );
  console.log(reduceObject(data))
  this.dispatchEvent(new CustomEvent('form-completed', {detail: reduceObject(data) }));

  //this.dispatchEvent(new CustomEvent('field-updated', {detail: {isValid: !this.invalid, name:e.target.name, checked:e.target.checked, value:e.target.value, targetType:e.target.type, eventType: e.type, timestamp: e.timeStamp}}));


  //Push valid form event
} else {
  //Push invalid form event

}









}





async postData (e, idToken) {
const form = this.shadowRoot.querySelector('form');
console.log(form.name);
/*
  const data= e.detail.data;
  const req='//us-central1-vanilla-666.cloudfunctions.net/app/actions/'+data.action;

    const response = await fetch(req, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
      },
      body:JSON.stringify(data)
    })
    const res = await response.json();
    console.log(res)

    */
}




  _changeHandler(e, data) {
  e.preventDefault();
  e.target.setCustomValidity('');
  this.invalid=!e.target.checkValidity();
  //this.dispatchEvent(new CustomEvent('field-updated', {detail: {isValid: !this.invalid, name:e.target.name, checked:e.target.checked, value:e.target.value, targetType:e.target.type, eventType: e.type, timestamp: e.timeStamp}}));


  if(this.invalid && e.target.custom){
    console.log('invalid custom');
  } else if (this.invalid){
    console.log(this.invalid);
  }else{
    console.log('valid');
    console.log({isValid: !e.target.invalid, name:e.target.name, value:e.target.value, targetType:e.target.type, eventType: e.type, timestamp: e.timeStamp})
  }

  }

  _focusHandler(e, data) {
  e.preventDefault();
  this.active=true;
  }

  _blurredHandler(e, data) {
  e.preventDefault();
  if(!e.target.value){
    this.active=false;
  }


  }






  _switchActiveState (e) {
    if(!this.sticky){
    const host= this.parentNode.host;
    host.active=false;
  }
  }



}


/*
<input
type="text"
autocomplete="off"
name="${data.name}"
pattern="${data.pattern}"
?required="${data.required}"
?invalid="${invalid}"
?custom
?active
@change="${e => this._changeHandler(e)}"
@focus="${e => this._focusHandler(e)}"
@blur="${e => this._blurredHandler(e)}"
>


*/
