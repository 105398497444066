
//const host= document.querySelector('app-shell').shadowRoot;
//const view= host && host.querySelector('app-router') ? host.querySelector('app-router').shadowRoot : null;

// Data sets
const appData= () => document.querySelector('app-shell').appData;

// Components
const shell= () => document.querySelector('app-shell').shadowRoot;
const router= () => shell().querySelector('app-router').shadowRoot;

function getComponent (id) {
  switch (id) {
    case 'shell':
    return shell();
    break;
    case 'router':
    return router();
    break;
    default:
    return false;
  }
}



const datalayer = (e, data) => {
  window.dataLayer= window.dataLayer||{};
window.dataLayer.push({
'event': data.event,
'gtm.element': e.target,
'gtm.elementClasses': e.target.className,
'gtm.elementId': e.target.id,
'behaves': data,
});
}


export function __handleInteraction(e, action ) {
  action= action || {type:'click'};
  let currentAction = actions.filter(res => res.type === action.type)[0];
  currentAction.callback && currentAction.callback(e, action);
}


export const actions= [
  {
    type: 'itemSelected',
    callback: (e, action, elem)=>{
      console.log(e.detail.value)
      e.target.active=false;


      // Push click or link click event first
      //action.event=e.target.href?'gtm.linkClick':'gtm.click';
      //datalayer(e, action);

    },
  },
  {
    type: 'toggle',
    callback: (e, action, elem, target )=>{

      console.log('toggle')
      target= getComponent(action.host);
      console.log(target)
      target= target ? target.getElementById(action.target): null;

      if(target){
        console.log(target)

      target.active=!target.active;
      }

    }
  },
  {
    type: 'switch',
    callback: (e, action, elem, target )=>{

      console.log('switch')
      target= getComponent(action.host);
      console.log(target)

      target= target ? target.getElementById(action.target): null;

      if(target){
      target.data= appData()[action.api.parent][action.api.id][action.api.category];
      target.active=!target.active;
      //target.active=true;
      }

      // Push click or link click event first
      //action.event='action';
      //datalayer(e, action);

/*
const host= elem.parentNode.host;
if(!host){
  console.log('no host');

} else {
  console.log(host)
}
*/


/*
      window.dataLayer= window.dataLayer||{};
window.dataLayer.push({
  'event':'behaves',
  'gtm.element': e.target,
  'gtm.elementClasses': e.target.className,
  'gtm.elementId': e.target.id,
  'behaves': e.target.data.action,
});

*/
      //console.log(host().getElementById(data))
//console.log(host().getElementById(src));

    //  console.log(src)
      //console.log(e.timeStamp)
    }
  },
  {
    type: 'navigate',
    callback: (e, action, elem)=>{
      e.preventDefault();
      // Push click or link click event first
      action.event=e.target.href?'gtm.linkClick':'gtm.click';
      //datalayer(e, action);
      //Switch Route if needed

if(action.href.substring(0, 2)==='//'){
  window.location.assign(action.href + window.location.search)
} else if(window.location.pathname !== action.href){
   window.history.pushState({"oldPath" : window.location.pathname}, '', action.href + window.location.search);
   window.dispatchEvent(new CustomEvent('route', {detail: {kicked: true}}));
 }


    }
  },
  {
    type: 'submit',
    callback: (e, action, elem)=>{
      console.log('submit')
      console.log(e.currentTarget);
      console.log(e.target.parentNode.data.name);

      console.log(action)

      //console.log('callback', route, params, query)
      //_redirectSignedInUserr()
    }
  },
  /*{
    type: 'authentification',
    callback: (data)=>{
      console.log('authentification')
      console.log(data)
      //console.log('callback', route, params, query)
      //_redirectSignedInUserr()
    },
    // Simple function guard
    guard: () => {
      return new Promise((resolve, reject) => {
          // Call an API for Athorization
          resolve(true)
          //setTimeout(function(){ resolve(false) }, 5000);
      })
    }
  },*/
  {
    type: 'click',
    callback: (data)=>{
      console.log('click')



      //console.log('callback', route, params, query)
      //_redirectSignedInUserr()
    }
  }
      ]
