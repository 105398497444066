import { LitElement, html } from 'lit-element';
import { renderText } from '../../text';


const renderParagraphElements = (data) => data.map(elems =>
	renderText(elems)
)


	export const renderParagraph = (data) => data
	? html `
		<p>
			${renderParagraphElements(data)}
		</p>
		`
	: null
