import { LitElement, html } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map.js';
import { renderLink } from '../../link';


export const renderText = (item) =>
item.small ? html`<small>${item.small}</small>`
: item.link ? html`${renderLink(item.link)}`
: item.dynamic ? html`dynamic`
: item.text
