import { LitElement, html } from 'lit-element';
import { renderText } from '../../../elements/text'
import { renderParagraph } from '../../../elements/paragraph'
import { renderButton } from '../../../elements/button'
import { renderLink } from '../../../elements/link'
import { renderTitle } from '../../../elements/title'
import { renderList } from '../../../elements/list'
import { renderSpan } from '../../../elements/span'
import { renderDropdown } from '../../../elements/dropdown'
import { renderSlot } from '../../../elements/slot'
import { renderFunction } from '../../../elements/function'
import { renderForm } from '../../../elements/form'
import { renderImage } from '../../../elements/image/index.js';
import { renderIcon } from '../../../elements/icon/index.js';
import { renderMenu } from '../../../elements/menu/index.js'
import { renderDiv } from '../../../elements/div/index.js'


export const renderElements = (data, src) => data.map(elems => elems.div
? renderDiv (elems.div, renderElements(elems.div.elements) )
: elems.menu ? renderMenu(elems.menu, renderElements(elems.menu.elements)) //renderMenu(elems.menu)
: renderElement(elems)
)

const renderElement = (items) => items.text ? renderText(items.text) //window.elements.renderText(items.text)
: items.title ? renderTitle(items.title) //window.elements.renderTitle(items.title)
: items.paragraph ? renderParagraph(items.paragraph) //window.elements.renderParagraph(items.paragraph)
: items.link ? renderLink(items.link) //window.elements.renderLink(items.link)
: items.list ? renderList(items.list) //window.elements.renderList(items.list)
: items.code ? renderCode(items.code) //window.elements.renderCode(items.code)
: items.span ? renderSpan(items.span) //window.elements.renderSpan(items.span)
: items.slot ? renderSlot(items.slot) //window.elements.renderSlot(items.slot) //
: items.image ? renderImage(items.image) //window.elements.renderForm(items.form) //
: items.icon ? renderIcon(items.icon) //window.elements.renderForm(items.form) //
: items.button ? renderButton(items.button, renderElements(items.button.elements)) //window.elements.renderButton(items.button)
: items.dropdown ? renderDropdown(renderElement(items.dropdown.trigger), renderElements(items.dropdown.elements)) //window.elements.renderDropdown(items.dropdown)
: items.function ? renderFunction(items.function, renderElement) //window.elements.renderFunction(items.function, renderElements)
: items.form ? renderForm(items.form) //window.elements.renderForm(items.form) //
: console.log('default', items)
