import { LitElement, html } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map.js';

export const renderTitle = (item) => item.h0
? html`<h1><span>${item.h0}</span></h1>`
: item.h1 ? html`<h1>${item.h1}</h1>`
: item.h2 ? html`<h2>${item.h2}</h2>`
: item.h3 ? html`<h3>${item.h3}</h3>`
: item.h4 ? html`<h4>${item.h4}</h4>`
: item.h5 ? html`<h5>${item.h5}</h5>`
: html`<h6>${item.h6}</h6>`
