import { LitElement, html } from 'lit-element';
import '../../../../app-dropdown/app-dropdown.js';
import { actions, __handleInteraction } from '../../../_actions.js';


export const renderDropdown = (trigger, elements) => trigger
? html`
<app-dropdown class="w-100" @item-selected="${e => __handleInteraction(e, {"type":"itemSelected"})}">
<span slot="trigger">${trigger}</span>
<div slot="slot" class="dropmenu-content ">
${elements}
</div>
</app-dropdown>
`
: null

//<p value="bla">ccccccc</p>
//<button value="bli">ccccccc dd d</button>
