import { LitElement, html } from 'lit-element';
import { renderText } from '../../text';


const renderListElements = (li) => li.map(item => html`
	<li> ${item.map(text => renderText(text))} </li>
	`)


	export const renderList = (li) => html`<ul>
	${renderListElements(li)}
	</ul>`
