import { LitElement, html } from 'lit-element';


export const renderDiv = (data, elems) => data ?
html`
<div class="${data.styles||''}" id="${data.id||''}">
${elems}
</div>
`
: null
