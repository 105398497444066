import { LitElement, html } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map.js';
import { renderButton } from '../../button/index.js';
import '../../../../app-form/app-form.js';

import { actions, __handleInteraction } from '../../../_actions.js';

//import '../../../../app-input/app-input.js';


const _postData = async (e) => {
  const sessionToken = document.cookie.replace(/(?:(?:^|.*;\s*)__session\s*\=\s*([^;]*).*$)|^.*$/, "$1");
  const data= e.detail;
  const req='//us-central1-vanilla-666.cloudfunctions.net/app/actions/'+e.target.data.name;

    const response = await fetch(req, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionToken
      },
      body:JSON.stringify(data)
    })
    const res = await response.json();
    console.log(res)

}




const renderFormFieldsetElements = (data) => data.map((element) =>
element ?  html `<app-input ?active .data="${element.input}"></app-input>`
: null
)

const renderFormFieldsets = (data) => data
? data.map((element) => html`
<fieldset id=${element.id}>
${element.legend?html`<legend>${element.legend}</legend>`:null}
<div class="flex ${element.class||''}">
${renderFormFieldsetElements(element.elements || [])}
</div>
  `
)
: null





function _handleFormSubmit(e){
  e.preventDefault();

  console.log(e.target.parentElement);
}


export const renderFormvvv = async (data) => {
  try {
     const test= await renderFormGood(data);
     return test;

  } catch(e) {
    console.log(e);
  }
}





export const renderForm2 = (data) => data ? html`
<form name="${data.name}">
${renderFormFieldsets(data.fieldsets)}

</form>
`
: null


//renderButton(items.button)
//<button id="submit" class="tr" .action="${data.action}" .content="${data.content}" .params="${data.params}" ?active @click="${(e) => _handleFormSubmit(e)}"></button>



/*? html`
<form name="${data.name}">
${renderFormFieldsets(data.fieldsets)}
<button id="submit" class="tr" .action="${data.action}" .content="${data.content}" .params="${data.params}" ?active @click="${(e) => _handleFormSubmit(e)}"></button>

</form>
`
: null
*/


export const renderForm = (data, meta) => data
? html`

<app-form
.data="${data}"
@form-completed="${(e) => _postData(e)}"
>
${renderButton(data.submit)}
</app-form>
`
: null
